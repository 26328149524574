import React from "react";
import PropTypes from 'prop-types';

import ImageWithList from "./image-with-list";
import "../styles/images-with-list.less";

const ImagesWithListContainer = ({elements, className}) =>
{
    if (!elements) { return null; }
    return <div className={"row justify-content-center images-with-list "+className}>
        {elements.map((element, index) => {
            return <ImageWithList key={index} element={element.node} />
        })}
    </div>
}

ImagesWithListContainer.propTypes = {
    elements: PropTypes.array.isRequired,
    className: PropTypes.string
}

export default ImagesWithListContainer;
