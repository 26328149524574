import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import {graphql} from 'gatsby';

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Banner from "../components/banner";
import Section from "../components/section";
import ImagesWithListContainer from '../components/images-with-list-container';
import Image from "../components/image";

// Fetches src/data/services.json
export const services = graphql`
    query ServicesQuery
    {
        allServicesJson {
            edges {
                node {
                    colSize
                    className
                    imageName
                    imageAlt
                    title
                    list { text }
                }
            }
        }
    }
`;

const Services = ({ intl, data }) => {
    return <Layout>
        <SEO title={intl.formatMessage({id: "Services"})} description=""/>
        <Banner 
            className="banner" 
            filename="services-banner.png" 
            title={intl.formatMessage({id: "Build real business with us"})}
            subtitle={intl.formatMessage({id: "We contribute our experience and validated methodologies with more than 1000 businesses"})}
        />
        <Section>
            <div className="row align-items-center page-introduction">
                <div className="col-md-6 introduction-left clients-left">
                <p>{intl.formatMessage({id: "Count on us to achieve your goals"})}</p>
                <h3>{intl.formatMessage({id: "Develop your entrepreneurial and investment environment"})}</h3>
                </div>
                <div className="col-md-6 introduction-right clients-right">
                <p>{intl.formatMessage({id: "We work with all types of entities that develop entrepreneurship and corporate venture environments. Years of experience in the world of business growth and investment allow us to help organizations connect technology, business and capital. Contact us to schedule a meeting."})}</p>
                <p>{intl.formatMessage({id: "Our strength lies in"})}:</p>
                <ul>
                    <li>{intl.formatMessage({id: "Extensive know-how in business and investment, with a business development model recognized as Best Practice by the International Business Incubator Association"})}</li>
                    <li>{intl.formatMessage({id: "A portfolio of multinational investors and a financing platform authorised by the National Securities Market Commission"})}</li>
                    <li>{intl.formatMessage({id: "A network of analysts, mentors and advisors specialized in a diversity of sectors and technologies"})}</li>
                </ul>
                </div>
            </div>
        </Section>
        <Section title={intl.formatMessage({id: "Get to know our services"})}>
            <ImagesWithListContainer elements={data.allServicesJson.edges} className="services" />
        </Section>

        <Section title={intl.formatMessage({id: "Want to know more?"})}>
            <div className="container contact-us-footer">
                <div className="contact-image">
                    <Image filename={"call_icon.png"} alt="Call us" />
                </div>
                <p>{intl.formatMessage({id: "Contact us at"})} <a href="tel:+34 91 193 00 61">+34 91 193 00 61</a></p>
                <p>{intl.formatMessage({id: "Or you can send us an email to"})} <a href="mailto://info@adventurees.com">info@adventurees.com</a></p>
            </div>
        </Section>
    </Layout>
}

export default injectIntl(Services);
