import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import Image from './image';

const ImageWithList = ({intl, element}) => {
    
    return <div className={"image-with-list-element col-md-"+element.colSize + " " + element.className}>
        <div className="row justify-content-center">
            <div className="image-container col-md-3">
                <Image filename={element.imageName} alt={intl.formatMessage({id: element.imageAlt})} />
                <h3>{intl.formatMessage({id: element.title})}</h3>
            </div>
            <div className="col-md-9 col-sm-7 list-container align-self-center">
                <ul>
                    {element.list.map((li, index) => {
                        return <li key={index}>{intl.formatMessage({id: li.text})}</li>
                    })}
                </ul>
            </div>
        </div>
    </div>
}

export default injectIntl(ImageWithList);
